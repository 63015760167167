import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"750"},model:{value:(_vm.cDialog),callback:function ($$v) {_vm.cDialog=$$v},expression:"cDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._t("headline",function(){return [_vm._v(" "+_vm._s(_vm.headline)+" ")]})],2),_c(VCardText,[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.message)+" ")]})],2),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.decide(false)}}},[_vm._v(" "+_vm._s(_vm.falseText)+" ")]),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.decide(true)}}},[_vm._v(" "+_vm._s(_vm.trueText)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }