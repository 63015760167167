import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,[_c(VSubheader,[_vm._v("My Posts")]),_c(VListItemGroup,{attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.blogPosts),function(blogPost){return _c(VListItem,{key:blogPost.id,on:{"click":_vm.emitPost}},[_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s('mdi-lead-pencil')}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(blogPost.title)}})],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }