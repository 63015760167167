import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"admin-container"},[_c(VToolbar,{attrs:{"flat":""}},[_c(VTabs,{on:{"change":_vm.checkPages},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,{attrs:{"href":"#tab-0"}},[_vm._v("Posts")]),_c(VTab,{attrs:{"href":"#tab-1"}},[_vm._v("Pages")]),_c(VTab,{staticClass:"d-none",attrs:{"href":"#tab-2"}},[_vm._v("Account")])],1),_c(VSpacer),_c(VMenu,{attrs:{"bottom":"","left":"","offset-x":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":""}},'v-btn',attrs,false),on),[_c(VAvatar,[_c('img',{attrs:{"src":(_vm.user && _vm.user.avatar) || require('@/assets/images/abstract-user-flat-4.svg'),"alt":((_vm.user && _vm.user.name) + " " + (_vm.user && _vm.user.surname))}})])],1)]}}])},[_c(VList,[_c(VListItemGroup,[_c(VListItem,{on:{"click":function($event){return _vm.goToTab('tab-2')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-account-circle")])],1),_c(VListItemTitle,[_vm._v("My Account")])],1),_c(VListItem,{on:{"click":_vm.logout}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-logout")])],1),_c(VListItemTitle,[_vm._v("Logout")])],1)],1)],1)],1)],1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{attrs:{"value":"tab-0"}},[_c('BlogPostEditor')],1),_c(VTabItem,{attrs:{"value":"tab-1"}},[_c('PageEditor',{ref:"pageEditor",attrs:{"pages":_vm.pages,"cached-pages":_vm.cachedPages}})],1),_c(VTabItem,{attrs:{"value":"tab-2"}},[_c('AccountEditor')],1)],1),_c('ConfirmDialog',{ref:"confirmDialog",attrs:{"activator-class":"activator","false-text":"Cancel","true-text":"Discard"},scopedSlots:_vm._u([{key:"headline",fn:function(){return [_vm._v(" The following items have unsaved changes ")]},proxy:true}])},[_c(VList,{attrs:{"flat":""}},[_c(VListItemGroup,_vm._l((_vm.unsaved),function(uns,i){return _c(VListItem,{key:i},[_vm._v(" "+_vm._s(uns || 'Untitled')+" ")])}),1)],1)],1),_c('ConfirmDialog',{ref:"logoutDialog",attrs:{"activator-class":"activator"},scopedSlots:_vm._u([{key:"headline",fn:function(){return [_vm._v("You are attempting to log out.")]},proxy:true}])},[_vm._v(" Are you sure? ")]),_c(VOverlay,{attrs:{"value":_vm.overlay}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }