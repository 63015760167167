import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{attrs:{"flat":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_c('p',{staticClass:"grey--text text--darken-2 text-h5"},[_vm._v("Tags")])]),_c(VExpansionPanelContent,[_c(VBtn,{staticClass:"grey--text text--darken-3",attrs:{"depressed":"","small":""},on:{"click":function($event){_vm.dialog = true}}},[_c(VIcon,{domProps:{"textContent":_vm._s('mdi-tag-plus-outline')}})],1),_c(VChipGroup,{attrs:{"column":""}},_vm._l((_vm.tags),function(tag,i){return _c(VChip,{key:tag.id,attrs:{"close":""},on:{"click:close":function($event){return _vm.removeTag(i)}}},[_vm._v(" "+_vm._s(tag.name)+" ")])}),1)],1)],1),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardActions,[_c(VBtn,{staticClass:"grey--text text--darken-3",attrs:{"depressed":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,{domProps:{"textContent":_vm._s('mdi-close')}})],1)],1),_c(VCardTitle,[_vm._v("Create Tag")]),_c(VCardText,[_c(VTextField,{attrs:{"label":"Tag Name","append-outer-icon":"mdi-plus-box-outline"},on:{"click:append-outer":function($event){return _vm.updateTags({ name: _vm.newTagName })}},model:{value:(_vm.newTagName),callback:function ($$v) {_vm.newTagName=$$v},expression:"newTagName"}}),_c(VExpansionPanels,{attrs:{"flat":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_c('p',{staticClass:"grey--text text--darken-2 text-h5"},[_vm._v("Existing Tags")])]),_c(VExpansionPanelContent,[_c(VChipGroup,{attrs:{"column":""}},_vm._l((_vm.existingTags),function(tag){return _c(VChip,{key:tag.id,on:{"click":function($event){return _vm.updateTags(tag)}}},[_vm._v(" "+_vm._s(tag.name)+" "),_c(VIcon,[_vm._v("mdi-check-circle-outline")])],1)}),1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }